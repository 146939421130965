<template>
  <div class="app-container">
    <div class="mytabel table-sm mr-0 ml-0 p-0">
      <div class="row table-sm mr-0 ml-0 p-0 mb-3 width-100">
<!--        <div
          v-can="'excelReports.2'"
          class="col-7 p-0 align-self-center font-weight-bold align-items-center"
        >
          <div class="d-flex">
            <h5 class="d-inline mr-2 font-weight-bold" style="width: 300px">
              {{ "ГЧП книга" }}
            </h5>

            <div class="text-center d-flex sorddata ml-3" style="width: 100%">
              <el-date-picker
                class="ml-4"
                style="width: 10%"
                size="medium"
                v-model="filterForm.start_date"
                type="date"
                :format="'dd.MM.yyyy'"
                :value-format="'dd.MM.yyyy'"
                :placeholder="$t('message.start_date')"
              ></el-date-picker>
              <el-date-picker
                class="ml-4"
                style="width: 10%"
                size="medium"
                v-model="filterForm.end_date"
                type="date"
                :format="'dd.MM.yyyy'"
                :value-format="'dd.MM.yyyy'"
                :placeholder="$t('message.end_date')"
              ></el-date-picker>
            </div>
            <el-button
              type="success"
              size="medium"
              class="ml-4"
              @click="printExcel"
              icon="el-icon-download"
              style="width: 20%"
              :loading="loadingButton"
              :disabled="loadingButton ? true : false"
            >
              {{ $t("message.download") }}
            </el-button>
          </div>
        </div>-->

<!--        <div
          v-can="'excelReports.2'"
          class="col-7 p-0 align-self-center font-weight-bold align-items-center mt-5"
        >
          <div class="d-flex">
            <h5 class="d-inline mr-2 font-weight-bold" style="width: 300px">
              {{ "363 форма 014-2" }}
            </h5>

            <div class="text-center d-flex sorddata ml-3" style="width: 100%">
              <el-date-picker
                class="ml-4"
                style="width: 10%"
                size="medium"
                v-model="filterForm2.start_date"
                type="date"
                :format="'dd.MM.yyyy'"
                :value-format="'dd.MM.yyyy'"
                :placeholder="$t('message.start_date')"
              ></el-date-picker>
              <el-date-picker
                class="ml-4"
                style="width: 10%"
                size="medium"
                v-model="filterForm2.end_date"
                type="date"
                :format="'dd.MM.yyyy'"
                :value-format="'dd.MM.yyyy'"
                :placeholder="$t('message.end_date')"
              ></el-date-picker>
            </div>
            <el-button
              type="success"
              size="medium"
              class="ml-4"
              @click="printExcel2"
              icon="el-icon-download"
              style="width: 20%"
              :loading="loadingButton2"
              :disabled="loadingButton2 ? true : false"
            >
              {{ $t("message.download") }}
            </el-button>
          </div>
        </div>-->

<!--        <div
          v-can="'excelReports.3'"
          class="col-7 p-0 align-self-center font-weight-bold align-items-center mt-5"
        >
          <div class="d-flex">
            <h5 class="d-inline mr-2 font-weight-bold" style="width: 300px">
              {{ "Полный отчет" }}
            </h5>

            <div class="text-center d-flex sorddata ml-3" style="width: 100%">
              <el-date-picker
                class="ml-4"
                style="width: 10%"
                size="medium"
                v-model="filterForm3.start_date"
                type="date"
                :format="'dd.MM.yyyy'"
                :value-format="'dd.MM.yyyy'"
                :placeholder="$t('message.start_date')"
              ></el-date-picker>
              <el-date-picker
                class="ml-4"
                style="width: 10%"
                size="medium"
                v-model="filterForm3.end_date"
                type="date"
                :format="'dd.MM.yyyy'"
                :value-format="'dd.MM.yyyy'"
                :placeholder="$t('message.end_date')"
              ></el-date-picker>
            </div>
            <el-button
              type="success"
              size="medium"
              class="ml-4"
              @click="printExcel3"
              icon="el-icon-download"
              style="width: 20%"
              :loading="loadingButton3"
              :disabled="loadingButton3 ? true : false"
            >
              {{ $t("message.download") }}
            </el-button>
          </div>
        </div>-->

<!--        <div
          v-for="report in reports"
          :key="report.id"
          class="col-7 p-0 align-self-center font-weight-bold align-items-center mt-5"
        >
          <div class="d-flex">
            <h5 class="d-inline mr-2 font-weight-bold" style="width: 300px">
              {{ report.name }}
            </h5>

            <div class="text-center d-flex sorddata ml-3" style="width: 100%">
              <el-date-picker
                class="ml-4"
                style="width: 10%"
                size="medium"
                v-model="start_date[report.id]"
                type="date"
                :format="'dd.MM.yyyy'"
                :value-format="'dd.MM.yyyy'"
                :placeholder="$t('message.start_date')"
              ></el-date-picker>
              <el-date-picker
                class="ml-4"
                style="width: 10%"
                size="medium"
                v-model="end_date[report.id]"
                type="date"
                :format="'dd.MM.yyyy'"
                :value-format="'dd.MM.yyyy'"
                :placeholder="$t('message.end_date')"
              ></el-date-picker>
            </div>
            <el-button
              type="success"
              size="medium"
              class="ml-4"
              @click="
                printReport(
                  report.id,
                  start_date[report.id],
                  end_date[report.id],
                  report.name
                )
              "
              icon="el-icon-download"
              style="width: 20%"
              :loading="loadingButton3"
              :disabled="loadingButton3 ? true : false"
            >
              {{ $t("message.download") }}
            </el-button>
          </div>
        </div>-->

        <div
            v-can="'excelReports.2'"
            class="col-7 p-0 align-self-center font-weight-bold align-items-center mt-5"
        >
          <div class="d-flex">
            <h5 class="d-inline mr-2 font-weight-bold" style="width: 300px">
              {{ $t(`message.orders`) }}
            </h5>

            <div class="text-center d-flex sorddata ml-3" style="width: 100%">
              <el-date-picker
                  class="ml-4"
                  style="width: 10%"
                  size="medium"
                  v-model="filterForm4.start_date"
                  type="date"
                  :format="'dd.MM.yyyy'"
                  :value-format="'dd.MM.yyyy'"
                  :placeholder="$t('message.start_date')"
              ></el-date-picker>
              <el-date-picker
                  class="ml-4"
                  style="width: 10%"
                  size="medium"
                  v-model="filterForm4.end_date"
                  type="date"
                  :format="'dd.MM.yyyy'"
                  :value-format="'dd.MM.yyyy'"
                  :placeholder="$t('message.end_date')"
              ></el-date-picker>
            </div>
            <el-button
                type="success"
                size="medium"
                class="ml-4"
                @click="printExcel4"
                icon="el-icon-download"
                style="width: 20%"
                :loading="loadingButton"
                :disabled="loadingButton ? true : false"
            >
              {{ $t("message.download") }}
            </el-button>
          </div>
        </div>

        <div
            v-can="'excelReports.2'"
            class="col-7 p-0 align-self-center font-weight-bold align-items-center mt-5"
        >
          <div class="d-flex">
            <h5 class="d-inline mr-2 font-weight-bold" style="width: 300px">
              {{ $t(`message.dds`) }}
            </h5>

            <div class="text-center d-flex sorddata ml-3" style="width: 100%">
              <el-date-picker
                  class="ml-4"
                  style="width: 10%"
                  size="medium"
                  v-model="filterForm5.start_date"
                  type="date"
                  :format="'dd.MM.yyyy'"
                  :value-format="'dd.MM.yyyy'"
                  :placeholder="$t('message.start_date')"
              ></el-date-picker>
              <el-date-picker
                  class="ml-4"
                  style="width: 10%"
                  size="medium"
                  v-model="filterForm5.end_date"
                  type="date"
                  :format="'dd.MM.yyyy'"
                  :value-format="'dd.MM.yyyy'"
                  :placeholder="$t('message.end_date')"
              ></el-date-picker>
            </div>
            <el-button
                type="success"
                size="medium"
                class="ml-4"
                @click="printExcel5"
                icon="el-icon-download"
                style="width: 20%"
                :loading="loadingButton"
                :disabled="loadingButton ? true : false"
            >
              {{ $t("message.download") }}
            </el-button>
          </div>
        </div>

        <div
            v-can="'excelReports.2'"
            class="col-7 p-0 align-self-center font-weight-bold align-items-center mt-5"
        >
          <div class="d-flex">
            <h5 class="d-inline mr-2 font-weight-bold" style="width: 300px">
              {{ $t(`message.warehouse`) }}
            </h5>

            <div class="text-center d-flex sorddata ml-3" style="width: 100%">
              <el-date-picker
                  class="ml-4"
                  style="width: 10%"
                  size="medium"
                  v-model="filterForm6.start_date"
                  type="date"
                  :format="'dd.MM.yyyy'"
                  :value-format="'dd.MM.yyyy'"
                  :placeholder="$t('message.start_date')"
              ></el-date-picker>
              <el-date-picker
                  class="ml-4"
                  style="width: 10%"
                  size="medium"
                  v-model="filterForm6.end_date"
                  type="date"
                  :format="'dd.MM.yyyy'"
                  :value-format="'dd.MM.yyyy'"
                  :placeholder="$t('message.end_date')"
              ></el-date-picker>
            </div>
            <el-button
                type="success"
                size="medium"
                class="ml-4"
                @click="printExcel6"
                icon="el-icon-download"
                style="
                width: 20%"
                :loading="loadingButton"
                :disabled="loadingButton ? true : false"
            >
              {{ $t("message.download") }}
            </el-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import axios from "axios";

export default {
  data() {
    return {
      data: null,
      start_date: {},
      end_date: {},
      filterForm: {
        start_date: null,
        end_date: null,
      },
      filterForm2: {
        start_date: null,
        end_date: null,
      },
      filterForm3: {
        start_date: null,
        end_date: null,
      },
      filterForm4: {
        start_date: null,
        end_date: null,
      },
      filterForm5: {
        start_date: null,
        end_date: null,
      },
      filterForm6: {
        start_date: null,
        end_date: null,
      },
      loadingButton: false,
      loadingButton2: false,
      loadingButton3: false,
      loadingButton4: false,
      loadingButton5: false,
      loadingButton6: false,
    };
  },
  mounted() {
    this.getReports();
  },
  computed: {
    ...mapGetters({
      reports: "excelColumns/list",
    }),
  },
  methods: {
    ...mapActions({
      print: "excelColumns/report",
      getReports: "excelColumns/index",
      excelReport: "orders/excelReport",
      excelReport2: "orders/excelReport2",
      excelReport3: "orders/excelReport3",
      excelReport4: "orders/excelReport4",
      excelReport5: "orders/excelReport5",
      excelReport6: "orders/excelReport6",
    }),
    printExcel() {
      this.loadingButton = true;
      this.excelReport(this.filterForm)
        .then((res) => {
          this.loadingButton = false;
          const url = window.URL.createObjectURL(
            new Blob([res], {
              type: "application/vnd.ms-excel",
            })
          );
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "ГЧП книга.xlsx");
          document.body.appendChild(link);
          link.click();
        })
        .catch((err) => {
          this.loadingButton = false;
          err.text().then((text) => {
            let error = JSON.parse(text);
            if (error.code == 422) {
              this.$notify({
                title: this.$t("message.validation"),
                type: "error",
                position: "bottom right",
                message: error.error.message,
              });
            } else {
              this.$alert(error);
            }
          });
        });
    },

    printExcel2() {
      this.loadingButton2 = true;
      this.excelReport2(this.filterForm2)
        .then((res) => {
          this.loadingButton2 = false;
          const url = window.URL.createObjectURL(
            new Blob([res], {
              type: "application/vnd.ms-excel",
            })
          );
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "363 форма 014-2.xlsx");
          document.body.appendChild(link);
          link.click();
        })
        .catch((err) => {
          this.loadingButton2 = false;
          err.text().then((text) => {
            let error = JSON.parse(text);
            if (error.code == 422) {
              this.$notify({
                title: this.$t("message.validation"),
                type: "error",
                position: "bottom right",
                message: error.error.message,
              });
            } else {
              this.$alert(error);
            }
          });
        });
    },

    printExcel3() {
      this.loadingButton3 = true;
      this.excelReport3(this.filterForm3)
        .then((res) => {
          this.loadingButton3 = false;
          const url = window.URL.createObjectURL(
            new Blob([res], {
              type: "application/vnd.ms-excel",
            })
          );
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "Полный отчет.xlsx");
          document.body.appendChild(link);
          link.click();
        })
        .catch((err) => {
          this.loadingButton3 = false;
          err.text().then((text) => {
            let error = JSON.parse(text);
            if (error.code == 422) {
              this.$notify({
                title: this.$t("message.validation"),
                type: "error",
                position: "bottom right",
                message: error.error.message,
              });
            } else {
              this.$alert(error);
            }
          });
        });
    },

    printExcel4() {
      this.loadingButton4 = true;
      this.excelReport4(this.filterForm4)
          .then((res) => {
            this.loadingButton4 = false;
            const url = window.URL.createObjectURL(
                new Blob([res], {
                  type: "application/vnd.ms-excel",
                })
            );
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", "Заказы.xlsx");
            document.body.appendChild(link);
            link.click();
          })
          .catch((err) => {
            this.loadingButton4 = false;
            err.text().then((text) => {
              let error = JSON.parse(text);
              if (error.code == 422) {
                this.$notify({
                  title: this.$t("message.validation"),
                  type: "error",
                  position: "bottom right",
                  message: error.error.message,
                });
              } else {
                this.$alert(error);
              }
            });
          });
    },

    printExcel5() {
      this.loadingButton5 = true;
      this.excelReport5(this.filterForm5)
          .then((res) => {
            this.loadingButton5 = false;
            const url = window.URL.createObjectURL(
                new Blob([res], {
                  type: "application/vnd.ms-excel",
                })
            );
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", "ДДС.xlsx");
            document.body.appendChild(link);
            link.click();
          })
          .catch((err) => {
            this.loadingButton5 = false;
            err.text().then((text) => {
              let error = JSON.parse(text);
              if (error.code == 422) {
                this.$notify({
                  title: this.$t("message.validation"),
                  type: "error",
                  position: "bottom right",
                  message: error.error.message,
                });
              } else {
                this.$alert(error);
              }
            });
          });
    },

    printExcel6() {
      this.loadingButton6 = true;
      this.excelReport6(this.filterForm6)
          .then((res) => {
            this.loadingButton6 = false;
            const url = window.URL.createObjectURL(
                new Blob([res], {
                  type: "application/vnd.ms-excel",
                })
            );
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", "Склад.xlsx");
            document.body.appendChild(link);
            link.click();
          })
          .catch((err) => {
            this.loadingButton6 = false;
            err.text().then((text) => {
              let error = JSON.parse(text);
              if (error.code == 422) {
                this.$notify({
                  title: this.$t("message.validation"),
                  type: "error",
                  position: "bottom right",
                  message: error.error.message,
                });
              } else {
                this.$alert(error);
              }
            });
          });
    },

    printReport(id, start_date, end_date, name) {
      let query = {
        id: id,
        start_date: start_date,
        end_date: end_date,
      };
      this.print(query)
        .then((res) => {
          const url = window.URL.createObjectURL(
            new Blob([res], {
              type: "application/vnd.ms-excel",
            })
          );
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", name + ".xlsx");
          document.body.appendChild(link);
          link.click();
        })
        .catch((err) => {
          this.loadingButton3 = false;
          err.text().then((text) => {
            let error = JSON.parse(text);
            if (error.code == 422) {
              this.$notify({
                title: this.$t("message.validation"),
                type: "error",
                position: "bottom right",
                message: error.error.message,
              });
            } else {
              this.$alert(error);
            }
          });
        });
    },
  },
};
</script>
